<template>
  <div class="account-member" style="width: 781px;">
    <el-button type="primary" icon="el-icon-plus"
      style="margin-bottom: 20px;background-color: #D9262C;border-color: #D9262C;" @click="add">邀请同事</el-button>
    <el-table :data="list" border style="with: 100%">
      <el-table-column align="center" :label="'昵称'" prop="name" :width="128"></el-table-column>
      <el-table-column align="center" :label="'职员手机号'" prop="mobile" :width="140"></el-table-column>
      <el-table-column align="center" :label="'查价权限'" :width="128">
        <template slot-scope="scope">
          <template v-if="scope.row.position == '企业管理员'">
            <i class="el-icon-circle-check" style="color: #D9262C;font-size: 30px"></i>
          </template>
          <template v-else>
            <template v-if="scope.row.roles.indexOf(1) != -1">
              <i class="el-icon-circle-check" style="color: #D9262C;font-size: 30px"></i>
            </template>
            <template v-else>
              <i class="el-icon-circle-close" style="color: #D9262C;font-size: 30px"></i>
            </template>
          </template>
        </template>
      </el-table-column>
      <el-table-column align="center" :label="'采购权限'" :width="128">
        <template slot-scope="scope">
          <template v-if="scope.row.position == '企业管理员'">
            <i class="el-icon-circle-check" style="color: #D9262C;font-size: 30px"></i>
          </template>
          <template v-else>
            <template v-if="scope.row.roles.indexOf(2) != -1">
              <i class="el-icon-circle-check" style="color: #D9262C;font-size: 30px"></i>
            </template>
            <template v-else>
              <i class="el-icon-circle-close" style="color: #D9262C;;font-size: 30px"></i>
            </template>
          </template>
        </template>
      </el-table-column>
      <el-table-column align="center" :label="'职位'" prop="position" :width="128"></el-table-column>
      <el-table-column align="center" :label="'操作'" :width="128">
        <template slot-scope="scope">
          <template v-if="scope.row.position == '企业管理员'">
            {{ scope.row.position }}
          </template>
          <template v-else>
            <a href="javascript:;" class='btn' style="color: #3385FF; margin-right: 20px;" @click="edit(scope.row)">修改</a>
            <a href="javascript:;" class='btn' style="color: red;" @click="editStatus(scope.row)">{{
              scope.row.account_status == 1 ? '冻结' : '解冻' }}</a>
          </template>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog title="提示" :visible.sync="show" width="30%" center :show-close="false" @close="close">
      <meng-from ref="mengFrom" @click="cdd" @close="close" :edit="editer" :type="type"></meng-from>
    </el-dialog>
  </div>
</template>
<script>
import mengFrom from '@/components/mengFrom'
export default {
  components: { mengFrom },
  data() {
    return {
      show: false,
      type: false,
      list: [],
      page: 1,
      checkList: [],
      editer: {}
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      var params = {
        page: this.page,
        per_page: 90
      }
      var res = await this.$http.get('api/employee', params);
      res.forEach(dn => {
        var arr = [];
        if (dn.has_roles.length) {
          dn.has_roles.forEach(item => {
            arr.push(item.id);
          })
        }
        dn.roles = arr;
      })
      this.list = res;
    },
    async editStatus(item) {
      console.log(item)
      this.$confirm('是否' + (item.account_status == 1 ? '冻结' : '解冻') + '该用户?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        var id = item.id;
        await this.$http.put('api/employee/status/' + id);
        this.getData();
      }).catch(() => {
      })

    },
    async edit(item) {
      var id = item.id;
      var res = await this.$http.get('api/employee/' + id);
      var arr = [];
      res.has_roles.forEach(dt => {
        arr.push(dt.id);
      })
      res.roles_ids = arr;
      this.type = true;
      this.show = true;
      setTimeout(() => {
        this.editer = res;
      }, 300)
    },
    add() {
      this.show = true;
      this.type = false;
    },
    cdd() {
      this.getData();
      this.$refs.mengFrom.init();
      this.show = false;
    },
    close() {
      this.$refs.mengFrom.init();
      this.show = false;
    }
  }
}
</script>

<style scoped lang="scss">
.account-member ::v-deep .el-dialog__title {
  color: #D9262C;
}

.el-icon-circle-check:before {
  color: #D9262c;
}

a {
  text-decoration: none;
  display: inline-block;
}
</style>

