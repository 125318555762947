<template>
  <div class="member-from">
    <div class="items">
      <div class="label">手机号</div>
      <div class="flex1"><el-input v-model="params.mobile" type="number" placeholder="请输入内容"></el-input></div>
    </div>
    <div class="items">
      <div class="label">验证码</div>
      <div class="flex1"><el-input v-model="params.code" type="number" placeholder="请输入内容"></el-input></div>
      <div style="margin-left: 20px;width: 132px;"><el-button type="primary" @click="getCode">{{codeText}}</el-button></div>
    </div>
    <div class="items">
      <div class="label">昵称</div>
      <div class="flex1"><el-input v-model="params.name" placeholder="请输入内容"></el-input></div>
    </div>
    <div class="items">
      <div class="label">职位</div>
      <div class="flex1"><el-input v-model="params.position" :placeholder="defaultPosition"></el-input></div>
    </div>
    <div class="items">
      <div class="label">密码</div>
      <div class="flex1"><el-input v-model="params.password" placeholder="请输入内容" type="password"></el-input></div>
    </div>
    <div class="items">
      <div class="label">权限配置</div>
      <div class="flex1">
        <el-checkbox-group v-model="params.roles_ids">
          <el-checkbox :label="item.id" v-for="(item,index) in roles" :key="index">{{item.name}}</el-checkbox>
        </el-checkbox-group>
      </div>
    </div>
    <div class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="chrem">确 定</el-button>
    </div>
  </div>
</template>

<script>
  import md5 from 'js-md5'
  export default{
    props:{
      edit:{
        type: Object,
        default() {
          return {};
        }
      },
      type: {
        type: Boolean,
        default: false
      }
    },
    data(){
      return{
        params: {
          mobile: '',
          code: '',
          name: '',
          position: '',
          password: '',
          roles_ids: []
        },
        defaultPosition: '普通管理员',
        roles: [],
        key: process.env.VUE_APP_SIGNKEY,
        codeText: '获取验证码',
        timer: null,
        num: 60
      }
    },
    created() {
      this.getRoles();
    },
    watch: {
      edit(newVal){
        console.log(newVal);
        this.params = newVal;
      }
    },
    methods: {
      /* eslint-disable */
      init(){
        this.params = {
          mobile: '',
          code: '',
          name: '',
          position: '',
          password: '',
          roles_ids: []
        }
      },
      async chrem() {
        if(!this.mobile(this.params.mobile)) return this.$message.error('请输入正确的手机号码');
        if(!this.params.mobile) return  this.$message.error('请输入手机号码');
        if(!this.params.code) return  this.$message.error('请输入验证码');
        if(!this.params.name) return  this.$message.error('请输入昵称');
        // if(!this.params.position) return  this.$message.warning('请输入职位');
        if(!this.params.position) {
          this.params.position = this.defaultPosition
        }
        if(!this.params.password) return  this.$message.warning('请输入密码');
        if(this.params.roles_ids.length == 0) return  this.$message.warning('请选择权限');
        var res = null;
        if(this.type){
          res = await this.$http.put('api/employee/'+this.params.id, this.params);
        }else{
          res = await this.$http.post('api/employee', this.params);
        }
        this.$emit('click');
      },
      async getRoles() {
        var res = await this.$http.get('api/employee/roles');
        this.roles = res;
      },
      close() {
        this.init();
        this.$emit('close');
      },
      // 验证是否手机
      mobile(value) {
      	return /^1[3-9]\d{9}$/.test(value)
      },
      async getCode(){
        if(!this.mobile(this.params.mobile)) return this.$message.warning('请输入正确的手机号码');
        if(!this.params.mobile) return  this.$message.warning('请输入手机号码');

        const timestamp = parseInt(new Date().getTime() / 1000).toString()
        const params = {
          mobile: this.params.mobile,
          sign: md5(md5(timestamp) + md5(this.params.mobile) + md5(this.key)),
          timestamp: Number(timestamp)
        }
        const _this = this
        this.timer = setInterval(() => {
          _this.num = _this.num - 1
          if (_this.num <= 0) {
            clearInterval(_this.timer)
            _this.codeText = '获取验证码'
            _this.num = 60
          } else {
            _this.codeText = _this.num  + 's后重试'

          }
        }, 1000)
        console.log(params)
        await this.$http.post('/common/sendsms', params)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .member-from {
    ::v-deep input::-webkit-outer-spin-button,
    ::v-deep input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    .dialog-footer{
      text-align: center;
      padding-top: 50px;
    }
    .items{
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 20px;
      .flex1{
        flex: 1;
      }
      .label{
        width: 100px;
        text-align: right;
        padding-right: 10px;
      }
    }
  }
</style>
